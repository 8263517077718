.modal {
  &-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.3);
    visibility: hidden;
    opacity: 0;
    
    &.visible {
      visibility: initial;
      opacity: 1;
    }
  }

  &-body {
    gap: 24px;
    padding: 24px;
    max-width: min(400px, calc(100% - 32px));
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden;
  }

  &-header {
    align-self: flex-end;
  }

  &-close {
    width: 28px;
    height: 28px;
  }

  &-text {
    gap: 8px;
    text-align: center;
  }

  &-title {
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
  }

  &-subtitle {
    font-size: 15px;
    line-height: 21px;
    color: #838998;
  }

  &-btn {
    gap: 12px;
    padding: 20px 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    border-radius: 12px;

    img {
      width: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    &-body {
      padding-top: 16px;
    }

    &-close {
      width: 24px;
      height: 24px;
    }

    &-title {
      font-size: 18px;
      line-height: 25px;
    }
  
    &-subtitle {
      font-size: 14px;
      line-height: 20px;
    }
  
    &-btn {
      padding: 16px 24px;
      font-size: 13px;
      line-height: 18px;
  
      img {
        width: 15px;
      }
    }
  }
}
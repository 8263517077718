.form {
  &-container {
    padding: 40px;
  }

  &-card {
    gap: 40px;
    padding: 80px 160px;
    border-radius: 40px;
  }

  &-header {
    gap: 16px;
  }

  &-main {
    gap: 40px;
    width: 560px;
  }

  &-inputs {
    gap: 16px;

    input {
      padding: 20px;
      border-radius: 12px;
      border: none;
      outline: none;
      background: #1F2939;

      &:hover {
        background: #253144;
      }
    }
  }

  &-submit-btn {
    padding: 20px 40px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 40px;

    &:disabled {
      color: rgba(255, 255, 255, 0.5);
      background: linear-gradient(90deg, rgba(171, 44, 0, 0.5) 0%, rgba(226, 58, 0, 0.5) 50%);
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .form {
    &-container {
      padding: 0;
    }

    &-card {
      gap: 24px;
      padding: 32px 16px;
      border-radius: 20px;
    }

    &-header {
      gap: 8px;
    }
  
    &-main {
      gap: 24px;
      width: 100%;
    }

    &-inputs {
      gap: 16px;
  
      input {
        padding: 16px 20px;
      }
    }
  
    &-submit-btn {
      padding: 16px 40px;
      font-weight: 600;
      border-radius: 24px;
    }
  }
}
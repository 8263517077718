@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800');

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #0B1320;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  transition: all 500ms;
}

a {
  color: initial;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input, textarea {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

img {
  height: 100%;
  object-fit: contain;
}

button {
  border: none;
}

.section-title {
  font-size: 40px;
  font-weight: 800;
  line-height: 55px;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.al-it-center {
  align-items: center;
}

.al-it-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.white {
 color: #ffffff;
}

.white-80 {
  color: rgba(255, 255, 255, 0.8);
}

.white-40 {
  color: rgba(255, 255, 255, 0.4);
}

.gray {
  color: #868688;
}

.primary {
  color: #E23A00;
}

.black-bg {
 background-color: #0B1320 !important;
}

@mixin gradient-animation( $start, $end ){
  background: $start;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &:before {
    background: $end;
    content: '';    
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity 500ms;
  }
  &:hover {
     &:before {
       opacity: 1; 
     }
  }
}

.gradient {
  @include gradient-animation(
    linear-gradient(90deg, #ab2c00 0%, #e23a00 50%),
    linear-gradient(270deg, #ab2c00 0%, #e23a00 50%)
  );
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
    line-height: 19px;
  }

  input, textarea {
    font-size: 14px;
    line-height: 19px;
  }

  .section-title {
    font-size: 24px;
    line-height: 33px;
  }
}
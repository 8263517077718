.stats {
  padding: 80px 40px 40px;

  &-list {
    gap: 16px;
  }

  &-item {
    gap: 8px;
    flex-basis: 25%;
    padding: 24px 32px;
    border-radius: 12px;
    border: 1px solid #F2F2F2;
    
    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    }
  }

  &-title {
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  }
}

@media screen and (max-width: 1024px) {
  .stats {
    padding: 24px 16px;

    &-list {
      gap: 8px;
      flex-wrap: wrap;
    }

    &-item {
      gap: 4px;
      flex-basis: calc(50% - 4px);
      padding: 12px 16px;
      border: 1px solid #F2F2F2;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
    
      &:hover {
        box-shadow: none;
      }
    }

    &-title {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
.main-clients {
  gap: 40px;
  padding: 40px 80px;

  &-list {
    flex-wrap: wrap;
  }

  &-item {
    width: 20%;
    height: 160px;
  }
}

@media screen and (max-width: 1024px) {
  .main-clients {
    gap: 16px;
    padding: 24px 16px;

    &-item {
      width: fit-content;
      height: 100px;
      flex-basis: 50%;
    }
  }
}
@import "../../index.scss";

.cards {
  gap: 40px;
  padding: 40px;

  &-header {
    gap: 24px;
  }

  &-tabs {
    gap: 16px;
    border-bottom: 1.5px solid #EDEEF0;
  }

  &-tab-item {
    margin-bottom: -1px;
    padding: 20px 16px;
    font-size: 15px;
    line-height: 20px;
    color: #4F4F4F;
    white-space: nowrap;

    &:hover {
      color: rgba(226, 58, 0, 0.6);
      border-bottom: 1.5px solid rgba(226, 58, 0, 0.6);
    }

    &.active {
      color: #E23A00;
      border-bottom: 1.5px solid #E23A00;
    }
  }

  &-list {
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
  }

  &-item {
    position: relative;
    aspect-ratio: 668 / 360;
    flex-basis: calc(50% - 12px);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;

    &:hover {
      .cards-item-text {
        margin-bottom: 0;
      }

      .overlay, .additional, .play {
        opacity: 1;
      }
    }

    .overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
      border-radius: 16px;
    }

    &-text {
      margin-bottom: -38px;
      padding: 24px 32px;
      width: 100%;
      align-self: flex-end;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
      border-radius: 16px;

      &-left {
        gap: 16px;

        .main-text {
          gap: 8px;

          .section-title {
            text-transform: uppercase;
          }
        }

        .additional {
          opacity: 0;
        }
      }

      .play {
        opacity: 0;
        height: 48px;
        width: 48px;
      }
    }
  }
}

.portfolio {
  &-wrapper {
    gap: 80px;
  }

  &-watch-btn {
    gap: 16px;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 16px 32px;
    border-radius: 32px;
    border: none;

    @include gradient-animation(
      #0B1320,
      linear-gradient(90deg, #ab2c00 0%, #e23a00 50%)
    );

    .text {
      margin-right: -31px;
    }

    .arrow {
      opacity: 0;
    }

    &:hover {
      .text {
        margin-right: 0;
      }
      
      .arrow {
        opacity: 1;
      }
    }
  }
}

.embed {
  &-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background: rgba($color: #000000, $alpha: 0.8);
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: initial;
      opacity: 1;

      .iframe {
        transform: translateY(0);
      }
    }

    .vimeo-table {
      margin: 0 auto;
      max-width: 940px;
      width: 100%;
      height: 100%;
      display: table;
    }

    .vimeo-table-cell {
      width: 100%;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }

    .vimeo-padding {
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;
      height: 0;

      &.vertical {
        padding-bottom: 98%;
      }
    }

    .youtube-display {
      height: 100%;
    }

    .iframe {
      border: none;
      transform: translateY(100%);
      width: 100%;
      height: 100%;

      &.vimeo {
        position: absolute;
        top: 0;
        left: 0;
      }

      &.youtube {
        aspect-ratio: 16 / 9;
        max-width: 940px;
        width: 100%;
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .cards {
    gap: 16px;
    padding: 24px 16px;

    &-tabs {
      gap: 8px;
      margin: 0 -16px;
      padding: 0 16px;
      border-bottom: none;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-tab-item {
      margin-bottom: 0;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 19px;
      border-radius: 16px;
      color: #1F2939;
      background: #F7F8FA;
  
      &:hover {
        color: none;
        border-bottom: none;
      }
  
      &.active {
        border-bottom: none;
        color: #FFFFFF;
        background: linear-gradient(90deg, #AB2C00 0%, #E23A00 50%);
      }
    }

    &-list {
      gap: 16px;
    }

    &-item {
      flex-basis: 100%;

      .overlay {
        display: none;
      }
  
      &-text {
        margin: 0;
        padding: 16px;
  
        &-left {
          max-width: 80%;
          
          .main-text {
            gap: 4px;

            .section-title {
              font-size: 28px;
              line-height: 35px;
            }
          }
  
          .additional {
            opacity: 1;
            display: none;
          }
        }
  
        .play {
          opacity: 1;
          height: 32px; 
          width: 32px;
        }
      }
    }
  }
  
  .portfolio {
    &-wrapper {
      gap: 40px;
    }
  
    &-watch-btn {
      gap: 12px;
      padding: 16px 24px;
      font-size: 14px;
      line-height: 19px;
      background: #E23A00 !important;
  
      .text {
        margin-right: 0px;
      }
  
      .arrow {
        opacity: 1;
      }
    }
  }

  .embed {
    &-overlay {
      .vimeo-padding {
        &.vertical {
          padding-bottom: 122%;
        }
      }
    }
  }
}
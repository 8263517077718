.footer {
  padding: 80px 40px;
  flex-direction: row-reverse;
  border-top: 1px solid #EDEEF0;

  &-left {
    gap: 24px;

    &-logo {
      height: 28px;
    }

    &-text {
      gap: 8px;
      font-size: 14px;
      line-height: 19px;

      .dot-4px {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #0B1320;
      }
    }
  }

  &-right {
    gap: 80px;

    &-top {
      gap: 80px;
    }
    
    &-item {
      gap: 12px;
    }

    &-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }

    &-subtitle {
      gap: 8px;
    }

    &-text {
      gap: 8px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    gap: 24px;
    padding: 40px 16px;
    flex-direction: column;

    &-left {
      gap: 12px;

      &-logo {
        height: 20px;
      }

      &-text {
        gap: 16px;
        font-size: 13px;
        line-height: 18px;
      }
    }

    &-right {
      gap: 16px;
      flex-direction: column;
  
      &-top {
        gap: 20px;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
      
      &-item {
        gap: 4px;
      }
  
      &-title {
        font-size: 16px;
        line-height: 22px;
      }
  
      &-subtitle {
        gap: 4px;
        text-wrap: nowrap;

        &.media {
          gap: 16px;
          flex-direction: row;
        }
      }
  
      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
      }
    }
    
    &-mobile {
      gap: 24px;

      .contacts {
        gap: 16px;
      }

      .logos {
        gap: 16px;
        
        img {
          padding: 14px;
          border-radius: 8px;
          background: #F7F8FA;
        }
      }
    }
  }
}
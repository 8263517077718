.about {
  &-us {
    margin-top: 40px;
    padding: 40px;
    gap: 80px;
  }

  &-title {
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  }

  &-left {
    gap: 20px;

    &-top {
      gap: 16px;
    }

    &-text {
      gap: 8px;
    }
  }

  &-right {
    gap: 40px;
    font-size: 15px;
    line-height: 20px;

    &-top, &-bottom {
      gap: 8px;
    }

    &-title {
      color: #B4B4B4;
    }

    &-text {
      gap: 4px;

      &-item {
        font-weight: 600;
      }
    }
  }
}

.directions {
  padding: 0 40px;

  &-card {
    gap: 40px;
    padding: 40px;
    border-radius: 20px;
  }

  &-top {
    gap: 16px;
  }

  &-title {
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  }

  &-text {
    gap: 8px;
  }

  &-bottom {
    gap: 24px;
  }

  &-item {
    position: relative;
    padding: 40px 90px 40px 24px;
    flex-basis: 100%;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-position: top 50% right -46px;
    background-color: #1F2939;

    & > img.item-bg-left {
      position: absolute;
      left: 15px;
      height: 130px;
    }
    
    .item {
      &-text {
        gap: 12px;
        z-index: 1;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
      }

      &-subtitle {
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
}

.videos {
  gap: 24px;
  padding: 64px 40px;

  &-list {
    gap: 24px;
    flex-wrap: wrap;

    .video-bg {
      position: relative;
      aspect-ratio: 668/360;
      flex-basis: calc(100% / 3 - 16px);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 16px;
      overflow: hidden;
    }

    .video-item {
      padding: 24px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);

      &-text {
        gap: 4px;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
      }

      &-subtitle {
        font-size: 15px;
        line-height: 21px;
      }

      .to-portfolio {
        gap: 4px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.process {
  padding: 0 40px;

  &-card {
    gap: 24px;
    padding: 40px;
    border-radius: 20px;
  }

  &-list {
    gap: 16px;
  }

  &-item {
    gap: 8px;
    padding: 20px;
    color: #D0D9EA;
    border-radius: 12px;
    background: linear-gradient(99deg, #1B222F 0%, rgba(27, 34, 47, 0.00) 100%);
  }

  &-step {
    font-size: 13px;
    line-height: 18px;
  }

  &-text {
    font-weight: 600;
  }
}

.team {
  gap: 40px;
  padding: 64px 40px;

  &-top {
    gap: 16px;
  }

  &-text {
    gap: 8px;
  }

  &-bottom {
    gap: 24px;
  }

  &-card {
    gap: 20px;

    & > img.photo {
      height: 280px;
    }
  }

  &-member {
    gap: 8px;
  }

  &-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }

  &-role {
    font-size: 15px;
    line-height: 20px;
  }
}

.about-clients {
  padding: 0 40px 80px;

  &-card {
    gap: 40px;
    padding: 40px;
    border-radius: 20px;
  }

  &-left {
    gap: 16px;
    flex: 1 1 50%;
    width: 500px;
  }

  &-text {
    font-size: 15px;
    line-height: 21px;
  }

  &-right {
    gap: 16px 8px;
    flex: 1 1 100%;
    height: fit-content;
    flex-wrap: wrap;
  }

  &-item {
    width: calc(25% - 6px);

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .about {
    &-us {
      margin-top: 0;
      padding: 24px 16px;
      gap: 24px;
    }

    &-title {
      font-size: 24px;
      line-height: 33px;
    }

    &-right {
      gap: 16px;
    }

    &-right {
      gap: 20px;
    }
  }

  .directions {
    padding: 0;
  
    &-card {
      gap: 24px 16px;
      padding: 24px;
    }
  
    &-top {
      gap: 8px;
    }
  
    &-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
  
    &-bottom {
      gap: 8px;
    }
  
    &-item {
      position: relative;
      padding: 20px 97px 20px 16px;
      background-position: top 50% right 0;
      background-size: 80px;
  
      & > img.item-bg-left {
        position: absolute;
        left: 10px;
        height: 70px;
      }
      
      .item {
        &-text {
          gap: 4px;
        }

        &-title {
          font-size: 18px;
          line-height: 25px;
        }
  
        &-subtitle {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
  
  .videos {
    gap: 16px;
    padding: 24px 16px;
  
    &-list {
      gap: 16px;
      flex-wrap: nowrap;
  
      .video-item {
        padding: 16px;
  
        &-text {
          gap: 2px;
        }
  
        &-title {
          font-size: 18px;
          line-height: 25px;
        }
  
        &-subtitle {
          max-width: 70%;
          font-size: 14px;
          line-height: 20px;
        }
  
        .to-portfolio {
          img {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }
  
  .process {
    padding: 0;
  
    &-card {
      padding: 24px 16px;
    }
  
    &-list {
      gap: 0;
    }
  
    &-item {
      gap: 4px;
      padding: 16px;
      background: linear-gradient(1deg, #1B222F 0.54%, rgba(27, 34, 47, 0.00) 110.14%);
    }
  
    &-step {
      font-size: 12px;
      line-height: 17px;
    }
  
    &-text {
      font-size: 15px;
      line-height: 21px;
    }
  }
  
  .team {
    gap: 24px;
    padding: 24px 16px;
  
    &-top {
      gap: 8px;
    }

    &-text {
      font-size: 14px;
      line-height: 20px;
    }
  
    &-bottom {
      gap: 24px 16px;
      flex-wrap: wrap;
    }
  
    &-card {
      gap: 12px;
      width: calc(50% - 8px);
  
      & > img.photo {
        height: 200px;
      }
    }
  
    &-member {
      gap: 4px;
    }
  
    &-name {
      font-size: 15px;
      line-height: 20px;
    }
  
    &-role {
      font-size: 13px;
      line-height: 18px;
    }
  }
  
  .about-clients {
    padding: 0;
  
    &-card {
      gap: 24px;
      padding: 24px 16px;
    }
  
    &-left {
      gap: 8px;
      width: auto;
    }
  
    &-text {
      font-size: 14px;
      line-height: 20px;
    }
  
    &-right {
      gap: 8px;
    }

    &-item {
      width: calc(50% - 4px);
      max-height: 100px;
    }
  }  
}
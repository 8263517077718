.brief {
  padding: 80px;
  position: relative;

  &.not-selected {
    padding: 240px 80px;
  }

  &-item {
    gap: 16px;

    &.type {
      margin-bottom: 40px;
    }

    &-invisible {
      gap: 40px;
      display: none;
      opacity: 0;
      z-index: -1;

      &.visible {
        display: flex;
        opacity: 1;
        z-index: 0;
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  }

  &-textarea, &-input {
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    outline: none;
  }

  &-textarea::placeholder,
  &-input::placeholder {
    color: rgba(31, 41, 57, 0.40);
  }

  &-textarea {
    width: 640px;
    height: 240px !important;
    border-radius: 12px;
    border: 1px solid #0B1320;
    resize: none;
  }

  &-input {
    width: 480px;
    border: none;
    border-bottom: 1.5px solid #1F2939;

    &.tenge {
      padding-right: 40px;
      background: url("../../assets/icons/tenge_icon.svg") no-repeat right 20px center;
    }
  }

  &-file {
    padding: 16px;
    width: 400px;
    border-radius: 8px;
    background: #F7F8FA;

    &-input {
      display: none;
    }

    &-text {
      gap: 4px;
    }

    &-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    &-ext {
      font-size: 14px;
      line-height: 19px;
      color: #A1A5AD;
    }

    &-label {
      gap: 16px;
      padding: 16px;
      width: 400px;
      height: 80px !important;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      color: #A1A5AD;
      border-radius: 8px;
      border: 1px solid #DBE2EF;
    }
  }

  &-footer {
    gap: 24px;
  }

  &-btn {
    padding: 16px 64px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 24px;

    &:disabled {
      background: linear-gradient(90deg, rgba(171, 44, 0, 0.5) 0%, rgba(226, 58, 0, 0.5) 50%);
      pointer-events: none;
    }

    &-right {
      font-size: 15px;
      line-height: 21px;
      color: #828282;
    }
  }
}

.select {
  &-list {
    gap: 12px;
    flex-wrap: wrap;
  }

  &-item {
    gap: 10px;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-wrap: nowrap;
    border-radius: 24px;
    background: #F7F8FA;
    transition: none;

    span {
      transition: none;
    }

    &.selected {
      .select-item-bullet {
        display: block;
        opacity: 1;
      }
    }

    &-bullet {
      display: none;
      opacity: 0;
      width: 12px;
      height: 12px !important;
      border-radius: 50%;
      border: 3px solid #FFFFFF;
    }
  }
}

@media screen and (max-width: 1024px) {
  .brief {
    padding: 24px 16px;

    &.not-selected {
      padding: 120px 16px;
    }

    &-item {
      &.type {
        margin-bottom: 32px;
      }

      &-invisible {
        &.visible {
          gap: 32px;
        }
      }
    }

    &-title {
      font-size: 16px;
      line-height: 22px;
    }

    &-textarea, &-input {
      padding: 16px 12px;
      font-size: 13px;
      line-height: 18px;
    }
  
    &-textarea {
      width: 100%;
      height: 160px !important;
      border-radius: 8px;
    }
  
    &-input {
      width: 100%;
  
      &.tenge {
        padding-right: 30px;
        background: url("../../assets/icons/tenge_icon.svg") no-repeat right 12px center;
      }
    }

    &-file {
      width: 100%;
  
      &-name {
        font-size: 14px;
        line-height: 19px;
      }
  
      &-ext {
        font-size: 13px;
        line-height: 18px;
      }
  
      &-label {
        padding: 24px 16px;
        width: 100%;
        height: fit-content !important;
        font-size: 13px;
        line-height: 18px;
      }
    }

    &-footer {
      flex-direction: column;
    }
  
    &-btn {
      font-size: 13px;
      line-height: 18px;
  
      &-right {
        display: block;
        font-size: 13px;
        line-height: 18px;
        text-align: center;

        span:first-child {
          margin-right: 3px;
        }
      }
    }
  }

  .select {
    &-list {
      gap: 12px 8px;
    }
  
    &-item {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.top {
  &-bg {
    aspect-ratio: 9 / 4;
    background: url("../../assets/images/main_bg.png") #0B1320 50% / cover no-repeat;
  }

  &.shrink {
    padding: 0 40px;

    .top-bg {
      border-radius: 20px;
    }
  }
}

.nav {
  padding: 25px 40px;

  &.not-main {
    border-bottom: 1px solid #EDEEF0;
  }

  &-logo {
    height: 26px;
  }

  &-right {
    gap: 48px;
  }

  &-links {
    gap: 48px;
  }

  &-link {
    font-weight: 600;
    cursor: pointer;

    &:hover:not(.fill-brief-btn) {
      color: #E23A00;
    }

    &.fill-brief-btn {
      padding: 10px 16px;
      border-radius: 16px;
    }
  }
}

.header {
  &-center {
    padding: 0 40px;
    flex: 1;
  }

  &-title {
    max-width: 60%;
    font-size: 72px;
    font-weight: 800;
    line-height: 90px;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  &-bottom {
    padding: 40px;
    font-weight: 600;

    .left {
      gap: 8px;
      padding: 8px 16px;
      font-size: 14px;
      line-height: 19px;
      border-radius: 16px;
    }

    .right {
      gap: 40px;
      color: rgba(255, 255, 255, 0.40);
    }
  }
}

@media screen and (max-width: 1024px) {
  .top {
    &-bg {
      aspect-ratio: 390 / 445;
    }

    &.shrink {
      padding: 0 16px;
  
      .top-bg {
        border-radius: 16px;
      }
    }
  }
  
  .nav {
    padding: 24px 16px;

    &-logo {
      height: 18px;
    }
  }

  .header {
    &-center {
      padding: 0 16px;

      &.flex-column {
        gap: 16px;

        .fill-brief-btn {
          width: fit-content;
        }
      }
    }

    &-title {
      max-width: initial;
      font-size: 32px;
      line-height: 40px;
      color: rgba(255, 255, 255, 0.90);
    }

    &-bottom {
      padding: 24px 16px;

      .left {
        padding: 0;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .right {
        gap: 24px;
      }
    }
  }

  .aside {
    padding: 24px 16px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    visibility: hidden;
    transform: translateX(-100%);
    transition: all 800ms;

    &.visible {
      visibility: initial;
      transform: translateX(0);
    }

    &-header {
      align-self: flex-end;
    }
    .nav-links {
      gap: 24px;

      .nav-link {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
    }

    &-lang {
      gap: 24px;
      font-size: 16px;
      line-height: 22px;
    }

    &-footer {
      padding: 16px 0;
    }

    &-footer-left {
      gap: 24px;
    }

    &-footer-contacts,
    &-footer-address {
      gap: 8px;
      font-size: 15px;
      line-height: 20px;
    }

    &-footer-right {
      gap: 24px;

      a {
        gap: 4px;
      }
    }
  }
}